import React, { useEffect, useState } from 'react';
import { Space, Table } from 'antd';
import type { TableProps } from 'antd';
import moment from 'moment';
import { useOutletContext } from "react-router-dom";
import axios from 'axios';
import { useTitle } from '../../hooks/title';

interface DataType {
  MonobankEvents: any[];
}

const EntityName = 'Invoice';
const EntityNameSlug = 'invoices';

const Component: React.FC = () => {
  const authContext: any = useOutletContext();
  const [data, setData] = useState<DataType[] | undefined>(undefined);
  const [tableParams, setTableParams] = useState({
    sortOrder: null,
    sortField: null,
    search: '',
  });
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    loading: true,
    showSizeChanger: false,
    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
  });

  // setting the page title
  useTitle('Invoices');

  const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter: any) => {
    loadData(pagination.current, pagination.pageSize, {
      ...tableParams,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const loadData = (page: number = 1, pageSize: number = 10, tableParams: any = {}) => {
    const start = page === 1 ? 0 : (page - 1) * pageSize;
    let url = `${process.env.REACT_APP_HOST_URL}/monobank/invoices?start=${start}&length=${pageSize}`;

    if (tableParams.search) {
      url += `&search=${tableParams.search}`;
    }

    if (tableParams.sortField && tableParams.sortOrder) {
      const sortOrder = tableParams.sortOrder === 'ascend'
        ? 'ASC'
        : 'DESC';

      url += `&order_by=${tableParams.sortField}&order_type=${sortOrder}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${authContext.auth.access_token}`
      }
    })
      .then((results) => {
        setData(results.data.data);
        setPagination({
          ...pagination,
          current: page,
          loading: false,
          total: results.data.recordsTotal.length,
        });
        setTableParams(tableParams);
      });
  }

  const columns: TableProps<DataType>['columns'] = [{
    title: 'Date',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text, record) => (
      moment(text).format('DD.MM.YYYY, HH:mm:ss')
    )
  }, {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
  }, {
    title: 'Full Name',
    dataIndex: 'full_name',
    sorter: true,
  }, {
    title: 'Payments',
    dataIndex: 'payments',
    sorter: true,
    render: (text, record) => {
      const payments = record.MonobankEvents.filter(item => item.status === 'success');
      if (!payments.length) {
        return 'N/A';
      }

      const total = payments.reduce((accumulator, current) => {
        return current.amount + accumulator;
      }, 0);

      return (
        <>{ payments.length } { payments.length > 1 ? 'payments' : 'payment' }, { (total / 100).toFixed(2) } UAH</>
      )
    }
  }];

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Table columns={columns} dataSource={data} rowKey="id" pagination={pagination} onChange={handleTableChange} />
    </Space>
  )
};

export default Component;