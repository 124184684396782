export default {
  container: {
    height: '100%',
    width: '100%',
  },
  tooltipContainer: {
    background: '#fff',
    padding: '15px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  },
  tooltipTitle: {
    fontWeight: 600,
    borderBottom: '1px solid #e8e8e882',
    padding: '0 0 10px',
  },
  tooltipContent: {
    padding: '5px 0 0',
  },
  tooltipRow: {
    margin: '5px 0 0',
  }
};