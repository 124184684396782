import { useEffect } from 'react';

export const useTitle = (title: string) => {
  useEffect(() => {
    const oldTitle = document.title;
    if (title) {
      document.title = title;
    }

    // reset title when component unmounts
    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};