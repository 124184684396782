import React, { useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";

import { useCookies } from 'react-cookie';
import {
  HomeOutlined,
  SwapOutlined,
  ShoppingOutlined,
  DatabaseOutlined,
  FundProjectionScreenOutlined,
  TagsOutlined,
  DeploymentUnitOutlined,
  ApartmentOutlined,
  DollarOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, Flex, Modal } from 'antd';

import styles from './styles';

const { Header, Content, Footer, Sider } = Layout;

const items = [{
  key: 'home',
  label: (
    <Link to="/">
      <HomeOutlined /> Home
    </Link>
  ),
}, {
  key: 'invoices',
  label: (
    <Link to="/invoices">
      <SnippetsOutlined /> Invoices
    </Link>
  ),
}, {
  key: 'payments',
  label: (
    <Link to="/payments">
      <DollarOutlined /> Payments
    </Link>
  ),
}, {
  key: 'purchases',
  label: (
    <Link to="/purchases">
      <ShoppingOutlined /> Purchases
    </Link>
  ),
}, {
  key: 'transfers',
  label: (
    <Link to="/transfers">
      <SwapOutlined /> Transfers
    </Link>
  ),
}, {
  key: 'categories',
  label: (
    <Link to="/categories">
      <DatabaseOutlined /> Categories
    </Link>
  ),
}, {
  key: 'projects',
  label: (
    <Link to="/projects">
      <FundProjectionScreenOutlined /> Projects
    </Link>
  ),
}, {
  key: 'departments',
  label: (
    <Link to="/departments">
      <ApartmentOutlined /> Departments
    </Link>
  ),
}, {
  key: 'military-units',
  label: (
    <Link to="/military-units">
      <DeploymentUnitOutlined /> Military Units
    </Link>
  ),
}, {
  key: 'tags',
  label: (
    <Link to="/tags">
      <TagsOutlined /> Tags
    </Link>
  ),
}];

interface TemplateProps {
  cloudinary: any,
  auth?: {
    email: string;
    access_token: string;
  }
}

const Template: React.FC<TemplateProps> = (props: TemplateProps) => {
  const location = useLocation();
  const defaultSelectedMenuItem = items.find(item => location.pathname.slice(1).includes(item.key)) || { key: 'home' };
  const { token: { colorBgContainer, borderRadiusLG }} = theme.useToken();
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);

  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = async () => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure you want to log out?',
      onOk: () => {
        removeCookie('access_token');
        window.location.href = '/';
      },
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  return (
    <Layout style={styles.layout}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(value) => {
          setCollapsed(value);
        }}
        collapsible
        collapsed={collapsed}
      >
        <div style={styles.logo} />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[defaultSelectedMenuItem.key]} items={items} />
      </Sider>
      <Layout>
        <Header style={{ lineHeight: '70px', padding: '0 40px', background: colorBgContainer }}>
          <Flex justify="space-between">
            <div>
              Hi, <strong>{ props.auth && props.auth.email }</strong>
            </div>
            <a href="#" style={{ color: '#001529' }} onClick={handleLogout}>
              Logout
            </a>
          </Flex>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet context={props} />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Hello, Ukraine ©{new Date().getFullYear()} Created by Incode Group
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Template;