import { Button, Form, Grid, Input, theme, Typography, Alert } from "antd";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Cloudinary } from "@cloudinary/url-gen";

import Template from './components/Template/Template';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Title } = Typography;
const cloudinary = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_NAME,
  }
});

export default function App(props: any) {
  const { token } = useToken();
  const screens = useBreakpoint();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authenticatedInfo, setAuthenticatedInfo] = useState<{ email: string, access_token: string } | null>(null);
  const [cookies, setCookie, removeCookie] = useCookies(['access_token']);

  useEffect(() => {
    // checking existing cookies and access_token
    // if exists -> try to authenticate and display the dashboard
    if (cookies.access_token) {
      axios.get(`${process.env.REACT_APP_HOST_URL}/auth/context`, {
        headers: {
          Authorization: `Bearer ${cookies.access_token}`,
        }
      })
        .then((result: any) => {
          setAuthenticatedInfo({
            email: result.data.email,
            access_token: cookies.access_token,
          });
        })
        .catch(error => {
          setError(error.response.data);
          removeCookie('access_token');
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div>Loading...</div>
    )
  }

  if (authenticatedInfo) {
    return (
      <Template auth={authenticatedInfo} cloudinary={cloudinary} />
    );
  }

  // the Login form submit handler
  // handling authentication request and install access_token if successful
  const onFinish = (values: any) => {
    axios.post(`${process.env.REACT_APP_HOST_URL}/auth/login`, values)
      .then((result: any) => {
        if (result.data.access_token) {
          setCookie('access_token', result.data.access_token);
          setAuthenticatedInfo({
            email: values.email,
            access_token: result.data.access_token,
          });
        }
      })
      .catch(error => {
        setError(error.response.data);
      });
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    error: {
      marginBottom: token.marginXL,
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  } as const;

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Sign in</Title>
        </div>

        {
          error
            ? (
              <Alert
                style={styles.error}
                message="Error"
                description={error}
                type="error"
                showIcon
              />
            )
            : null
        }


        <Form
          name="normal_login"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block={true} type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}