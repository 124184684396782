import React, { useEffect } from 'react';
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, notification } from 'antd';
import axios from 'axios';

const EntityName = 'Project';
const EntityNameSlug = 'projects';

const Component: React.FC = () => {
  const [form] = Form.useForm();
  const authContext: any = useOutletContext();
  const navigate = useNavigate();
  let { id } = useParams();

  // load existing entity from database (if editing)
  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}/${id}`, {
        headers: {
          Authorization: `Bearer ${authContext.auth.access_token}`
        }
      })
        .then(result => {
          form.setFieldsValue(result.data);
        })
        .catch(error => {
          notification.error({
            message: 'Error',
            description: `${EntityName} not found.`
          });
        });
    }
  }, []);

  // if no auth context -> display nothing (only for admins)
  if (!authContext || !authContext.auth.access_token) {
    return <>Forbidden</>;
  }

  // handling new entity creation or updating an existing entity
  const onFinish = (values: any) => {
    if (id) {
      return axios.put(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}/${id}`, values, {
        headers: {
          Authorization: `Bearer ${authContext.auth.access_token}`
        }
      })
        .then(() => {
          notification.success({
            message: 'Success',
            description:
              `The ${EntityName.toLowerCase()} has been updated successfully!`,
          });
          navigate(`/${EntityNameSlug}`);
        })
        .catch(error => {
          notification.error({
            message: 'Error',
            description:
              `The ${EntityName.toLowerCase()} has NOT been updated!`,
          });
        });
    }

    axios.post(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}`, values, {
      headers: {
        Authorization: `Bearer ${authContext.auth.access_token}`
      }
    })
      .then(() => {
        notification.success({
          message: 'Success',
          description:
            `New ${EntityName.toLowerCase()} has been created successfully!`,
        });
        navigate(`/${EntityNameSlug}`);
      })
      .catch(error => {
        notification.error({
          message: 'Error',
          description:
            `New ${EntityName.toLowerCase()} has NOT been created!`,
        });
      });
  }

  // handle errors within a form
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      layout="vertical">

      <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please specify the name.' }]} tooltip="This is a required field">
        <Input placeholder={`Please enter the name of the ${EntityName.toLowerCase()}`} />
      </Form.Item>

      <Form.Item
        name="url"
        label="Project URL"
        rules={[{ required: true, message: 'URL is required.' }, { type: 'url', warningOnly: true }, { type: 'string' }]}
      >
        <Input placeholder={`Please enter the URL of the ${EntityName.toLowerCase()} from the main website`} />
      </Form.Item>

      <Form.Item name="reference" label="Reference" tooltip="The last part of the URL (slug)">
        <Input placeholder={`Please enter the reference of the ${EntityName.toLowerCase()}`} />
      </Form.Item>

      <Form.Item name="jar_reference" label="Jar Reference" tooltip="Ask administrator to provide. Here is an example: 'F_823'. Typically it becomes known the 2nd day after the first donation using jars.">
        <Input placeholder={`Please enter the jar reference of the ${EntityName.toLowerCase()} (if jar exists)`} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default Component;