export default {
  layout: {
    height: '100%',
    minHeight: '100vh',
  },
  logo: {
    backgroundImage: 'url("/logo.png")',
    height: '70px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '80%',
    margin: '0 auto'
  }
};