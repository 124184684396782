import React, { useEffect, useState } from 'react';
import { Space, DatePicker, Divider, Row, Col } from 'antd';
import type { TableProps } from 'antd';
import { useOutletContext } from "react-router-dom";
import axios from 'axios';
import type { TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import PaymentsChart from './PaymentsChart/PaymentsChart';
import { useTitle } from '../../hooks/title';
import styles from './styles';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface DataType {
  name: string;
}

interface DatePickerParams {
  from: null | dayjs.Dayjs;
  to: null | dayjs.Dayjs;
}

const Dashboard: React.FC = () => {
  const authContext: any = useOutletContext();
  const [datePickerParams, setDatePickerParams] = useState<DatePickerParams>({
    from: dayjs().add(-1, 'year'),
    to: dayjs(),
  });
  const [data, setData] = useState(undefined);

  // setting the page title
  useTitle('Home');

  const loadData = (datePickerParams: DatePickerParams) => {
    let url = `${process.env.REACT_APP_HOST_URL}/monobank/donations/chart`;

    if (datePickerParams.from && datePickerParams.to) {
      url += `?from=${datePickerParams.from}&to=${datePickerParams.to}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${authContext.auth.access_token}`
      }
    })
      .then((results) => {
        setData(results.data.data);
        setDatePickerParams(datePickerParams);
      });
  }

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    { label: 'Last Year', value: [dayjs().add(-1, 'year'), dayjs()] },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (!dates) {
      return;
    }

    const [from, to] = dates;
    loadData({ from, to });
  };

  useEffect(() => {
    loadData(datePickerParams);
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <h1 style={styles.sectionTitle}>Payments</h1>
      <Divider />
      <div style={styles.rangePicker}>
        <Row>
          <Col>
            <div style={styles.rangePickerLabel}>
              Specify the dates range:
            </div>
            <RangePicker
              defaultValue={[datePickerParams.from, datePickerParams.to]}
              presets={rangePresets}
              onChange={onRangeChange} />
          </Col>
        </Row>
      </div>


      <PaymentsChart data={data} />
    </Space>
  );
}

export default Dashboard;