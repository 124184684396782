import React, { useEffect, useState } from 'react';
import { Space, Table, Button, Modal, notification } from 'antd';
import type { TableProps } from 'antd';
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from 'axios';
import { useTitle } from '../../hooks/title';

interface DataType {
  name: string;
  url: string;
}

const EntityName = 'Project';
const EntityNameSlug = 'projects';

const Component: React.FC = () => {
  const navigate = useNavigate();
  const authContext: any = useOutletContext();
  const [data, setData] = useState<DataType[] | undefined>(undefined);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    loading: true,
    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page: number, pageSize: number) => {
      loadData(page, pageSize);
    },
  });

  // setting the page title
  useTitle('Projects');

  const loadData = (page: number = 1, pageSize: number = 10) => {
    axios.get(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}?page=${page}&count=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${authContext.auth.access_token}`
      }
    })
      .then((results) => {
        setData(results.data.data);
        setPagination({
          ...pagination,
          current: page,
          loading: false,
          total: results.data.total,
        });
      });
  }

  const onEdit = (entity: any) => (event: any) => {
    event.preventDefault();
    navigate(`/${EntityNameSlug}/${entity.id}/edit`);
  }

  const onDelete = (entity: any) => (event: any) => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to delete the ${EntityName.toLowerCase()} called "${entity.name}"?`,
      onOk: () => {
        axios.delete(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}/${entity.id}`, {
          headers: {
            Authorization: `Bearer ${authContext.auth.access_token}`
          }
        })
          .then(() => {
            loadData();
            notification.success({
              message: 'Success',
              description:
                `${EntityName} has been removed successfully!`,
            });
          })
          .catch(error => {
            console.error(error);
          });
      },
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const columns: TableProps<DataType>['columns'] = [{
    title: 'Name',
    dataIndex: 'name',
  }, {
    title: 'Project URL',
    dataIndex: 'url',
  }, {
    title: 'Reference',
    dataIndex: 'reference',
    render: (text) => {
      return (
        <>{ text || 'N/A' }</>
      );
    }
  }, {
    title: 'Jar Reference',
    dataIndex: 'jar_reference',
    render: (text) => {
      return (
        <>{ text || 'N/A' }</>
      );
    }
  }, {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a href="#" onClick={onEdit(record)}>Edit</a>
        <a href="#" onClick={onDelete(record)}>Delete</a>
      </Space>
    ),
  },
];

  const handleAddNewEntity = () => {
    navigate(`/${EntityNameSlug}/new`);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Button onClick={handleAddNewEntity}>{ `Add new ${EntityName}` }</Button>
      <Table columns={columns} dataSource={data} rowKey="id" pagination={pagination} />
    </Space>
  )
};

export default Component;