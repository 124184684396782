export default {
  sectionTitle: {
    margin: '0 0 -15px',
  },
  rangePicker: {
    margin: '-10px 0 15px',
  },
  rangePickerLabel: {
    margin: '0 0 10px',
  }
};