import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import { message, notification, Button, Form, Input, DatePicker, Row, InputNumber, Select, Col, Upload } from 'antd';

import AsyncSelect from '../../Utils/AsyncSelect/AsyncSelect';

const { Option } = Select;

const EntityName = 'Purchase';
const EntityNameSlug = 'purchases';

interface LabelValue {
  label: string;
  value: string | number;
}

const App: React.FC = () => {
  const [form] = Form.useForm();
  const [isRateDisabled, setIsRateDisabled] = useState(false);
  const context: any = useOutletContext();
  const navigate = useNavigate();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  // load existing entity from database (if editing)
  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}/${id}`, {
        headers: {
          Authorization: `Bearer ${context.auth.access_token}`
        }
      })
        .then(result => {
          const values = {
            ...result.data,
            category: {
              value: result.data.category_id
            },
            date: dayjs(result.data.date),
            invoice_date: dayjs(result.data.invoice_date),
            tags: result.data.PurchaseTags.map((tag: any) => ({
              value: tag.tag_id,
            })),
            purchase_receipts: result.data.PurchaseReceipts.map((receipt: any, index: number) => {
              return {
                uid: receipt.cloudinary_id,
                status: 'done',
                name: `Receipt #${index + 1}`,
                url: context.cloudinary.image(receipt.cloudinary_id).toURL(),
              }
            }),
            purchase_invoices: result.data.PurchaseInvoices.map((invoice: any, index: number) => {
              return {
                uid: invoice.cloudinary_id,
                status: 'done',
                name: `Invoice #${index + 1}`,
                url: context.cloudinary.image(invoice.cloudinary_id).toURL(),
              }
            }),
          };
          if (result.data.project_id) {
            values.project = {
              value: result.data.project_id,
            };
          }

          console.log(values);
          form.setFieldsValue(values);
        })
        .catch(error => {
          notification.error({
            message: 'Error',
            description: `${EntityName} not found.`
          });
        });
    }
  }, []);

  const buildFormData = (values: any) => {
    const formData = new FormData();
    for (const name in values) {
      if (['purchase_invoices', 'purchase_receipts'].includes(name)) {
        if (values[name] && values[name].length) {
          values[name].forEach((file: any) => {
            const isLt2M = file.size / 1024 / 1024 < 2; // < 2MB
            if (file.originFileObj && !isLt2M) {
              throw new Error(`${file.name} file exceeds allowed maximum size 2MB.`);
            }

            // file.originFileObj means it was modified
            // file.uid means it was previously uploaded
            formData.append(`${name}`, file.originFileObj || file.uid);
          });
        }
      } else if (['category', 'project'].includes(name)) {
        if (values[name]) {
          formData.append(name, values[name].value);
        }
      } else if (['tags'].includes(name)) {
        if (values[name] && values[name].length) {
          values[name].forEach((tag: any, index: number) => {
            formData.append(`${name}[${index}]`, tag.value);
          });
        }
      } else {
        formData.append(name, values[name]);
      }
    }

    return formData;
  }

  const onFinish = (values: any) => {
    setIsLoading(true);

    let formData;
    try {
      formData = buildFormData(values);
    } catch (error: any) {
      message.error(error.message);
      setIsLoading(false);
      return false;
    }

    if (id) {
      return axios.put(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${context.auth.access_token}`
        }
      })
        .then(() => {
          setIsLoading(false);
          notification.success({
            message: 'Success',
            description:
              `The ${EntityName.toLowerCase()} has been updated successfully!`,
          });
          navigate(`/${EntityNameSlug}`);
        })
        .catch(error => {
          setIsLoading(false);
          notification.error({
            message: 'Error',
            description:
              `The ${EntityName.toLowerCase()} has NOT been updated!`,
          });
        });
    }

    axios.post(`${process.env.REACT_APP_HOST_URL}/${EntityNameSlug}`, formData, {
      headers: {
        Authorization: `Bearer ${context.auth.access_token}`
      }
    })
      .then((result) => {
        setIsLoading(false);
        notification.success({
          message: 'Success',
          description:
            `Purchase "${result.data.name}" has been created successfully!`,
        });
        navigate(`/purchases`);
      })
      .catch(error => {
        setIsLoading(false);
        notification.error({
          message: 'Error!',
          description: 'Purchase not created.',
        });
      });
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }

  const fetchAssociationsList = (association: string) => async function (term?: string): Promise<LabelValue[]> {
    const queryString = term
      ? `?search=${term}`
      : `?page=1&count=10`;

    return axios.get(`${process.env.REACT_APP_HOST_URL}/${association}${queryString}`, {
      headers: {
        Authorization: `Bearer ${context.auth.access_token}`
      }
    })
      .then((results) => {
        return results.data.data.map(
          (entity: { name: string, id: number }) => ({
            label: entity.name,
            value: entity.id,
          }),
        );
      });
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 < 2; // < 2MB
    if (!isLt2M) {
      message.error(`${file.name} file exceeds allowed maximum size 2MB.`);
      return false;
    }

    return false;
  }

  const handleAmountCalculation = (value: any) => {
    const formValues = form.getFieldsValue();

    // if UAH -> rate is always 1, so we multiply price and quantity
    // if not UAH -> consider rate for calculation
    const isUAH = formValues.currency === 'uah';
    const result = isUAH
      ? Number(formValues.price) * Number(formValues.quantity)
      : Number(formValues.price) * Number(formValues.rate) * Number(formValues.quantity);

    if (isUAH) {
      form.setFieldValue('rate', 1);
      setIsRateDisabled(true);
    } else if (isRateDisabled) {
      setIsRateDisabled(false);
    }

    form.setFieldValue('amount', isNaN(result) ? 0 : result);
  }

  const onlyIntegerFormatter = (value: any) => {
    if (/[^0-9]/.test(value)) {
      return value.replace(/[^0-9]/, '');
    }

    return value;
  }

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      layout="vertical">

      <Row gutter={20}>
        <Col span={8}>
          <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please specify the date.' }]}>
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="invoice_date" label="Invoice Date" rules={[{ required: true, message: 'Please specify the invoice date.' }]}>
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="invoice_number" label="Invoice Number" rules={[{ required: true, message: 'Please specify the invoice number.' }]} tooltip="This is a required field">
            <Input placeholder="Please enter the number of the invoice" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[20, 0]}>
        <Col>
          <Form.Item
            name="purchase_receipts"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            label="Receipt(s)">
            <Upload
              name="purchase_receipts"
              multiple={true}
              listType="picture"
              beforeUpload={beforeUpload}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="purchase_invoices"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            label="Invoices(s)">
            <Upload
              name="purchase_invoices"
              multiple={true}
              listType="picture"
              beforeUpload={beforeUpload}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please specify the name.' }]} tooltip="This is a required field">
            <Input placeholder="Please enter the name of the purchase" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={5}>
          <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please specify the price.' }]} tooltip="This is a required field">
            <InputNumber onChange={handleAmountCalculation} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: 'Please select currency!' }]}>
            <Select placeholder="Select the currency" onChange={handleAmountCalculation}>
              <Option value="uah">UAH</Option>
              <Option value="usd">USD</Option>
              <Option value="eur">EUR</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="rate" label="Rate" rules={[{ required: true, message: 'Please specify rate!' }]}>
            <InputNumber onChange={handleAmountCalculation} disabled={isRateDisabled} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: 'Please specify the quantity.' }]}
            tooltip="This is a required field">
            <InputNumber
              min={1}
              onChange={handleAmountCalculation}
              formatter={onlyIntegerFormatter} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="amount"
            label="Amount"
            tooltip="This is a required field. Calculates automatically."
            rules={[{ required: true, message: 'Please specify the amount.' }]} >
            <InputNumber disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item name="category" label="Category"  rules={[{ required: true, message: 'Please specify the category.' }]}>
            <AsyncSelect
              allowClear
              showSearch={true}
              debounceTimeout={300}
              placeholder="Select category"
              fetchOptions={fetchAssociationsList('categories')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="project" label="Project">
            <AsyncSelect
              allowClear
              showSearch={true}
              debounceTimeout={300}
              placeholder="Select project"
              fetchOptions={fetchAssociationsList('projects')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="tags" label="Tags">
        <AsyncSelect
          mode="multiple"
          allowClear
          showSearch={true}
          debounceTimeout={300}
          placeholder="Select tags"
          fetchOptions={fetchAssociationsList('tags')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default App;