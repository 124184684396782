import React, { useState } from 'react';
import moment from 'moment';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './styles';

interface Props {
  data: any;
}

const PaymentsChart: React.FC<Props> = ({ data }) => {
  const renderTooltip = (options: any) => {
    const { active, payload, label } = options;

    if (active && payload) {
      const [data] = payload;
      return (
        <div style={styles.tooltipContainer}>
          <div style={styles.tooltipTitle}>{ moment(label, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') }</div>
          <div style={styles.tooltipContent}>
            <div style={styles.tooltipRow}>
              Transactions: { data.payload.count }
            </div>
            <div style={styles.tooltipRow}>
              Total: <strong>{ data.payload.total } UAH</strong>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer >
        <BarChart
          width={500}
          height={520}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorDefault" x1="0" y1="0" x2="0" y2="1">
              <stop offset="35%" stopColor="#FFAD7A" stopOpacity={0.7}/>
              <stop offset="95%" stopColor="#FFAD7A" stopOpacity={1}/>
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="colorSelected" x1="0" y1="0" x2="0" y2="1">
              <stop offset="35%" stopColor="rgb(115 182 59)" stopOpacity={0.7}/>
              <stop offset="95%" stopColor="rgb(115 182 59)" stopOpacity={1}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          {
            data && data.length
              && <Tooltip content={renderTooltip} cursor={{ fill: '#2934620a' }} />
              || null
          }
          <Bar dataKey="total" fill="url(#colorDefault)" activeBar={<Rectangle fill="url(#colorSelected)" stroke="url(#colorSelected)" />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default PaymentsChart;