import React, { useEffect, useState } from 'react';
import { Space, Table, Input } from 'antd';
import moment from 'moment';
import type { TableProps, GetProps } from 'antd';
import { useOutletContext } from "react-router-dom";
import axios from 'axios';
import { useTitle } from '../../hooks/title';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

interface DataType {
  amount: number;
  bank: string;
  jar_name: string;
  full_name: string;
  email: string;
  destination: string;
  created_at: string;
  Invoice: any;
  ccy: number;
  payment_system: string;
  project: {
    url: string;
    name: string;
  }
}

function getCurrency (ccy: number): string {
  switch (ccy) {
    case 980:
      return 'UAH';
    default:
      return 'UAH';
  }
}

const Component: React.FC = () => {
  const authContext: any = useOutletContext();
  const [data, setData] = useState<DataType[] | undefined>(undefined);
  const [tableParams, setTableParams] = useState({
    sortOrder: null,
    sortField: null,
    search: '',
  });
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    loading: true,
    showSizeChanger: false,
    showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
  });

  // setting the page title
  useTitle('Payments');

  const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter: any) => {
    loadData(pagination.current, pagination.pageSize, {
      ...tableParams,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    loadData(1, 10, {
      ...tableParams,
      search: value,
    });
  };

  const loadData = (page: number = 1, pageSize: number = 10, tableParams: any = {}) => {
    const start = page === 1 ? 0 : (page - 1) * pageSize;
    let url = `${process.env.REACT_APP_HOST_URL}/monobank/donations?start=${start}&length=${pageSize}`;

    if (tableParams.search) {
      url += `&search=${tableParams.search}`;
    }

    if (tableParams.sortField && tableParams.sortOrder) {
      const sortOrder = tableParams.sortOrder === 'ascend'
        ? 'ASC'
        : 'DESC';

      url += `&order_by=${tableParams.sortField}&order_type=${sortOrder}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${authContext.auth.access_token}`
      }
    })
      .then((results) => {
        setData(results.data.data);
        setPagination({
          ...pagination,
          current: page,
          loading: false,
          total: results.data.recordsTotal,
        });
        setTableParams(tableParams);
      });
  }

  const columns: TableProps<DataType>['columns'] = [{
    title: 'Date',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text, record) => (
      moment(text).format('DD.MM.YYYY, HH:mm:ss')
    )
  }, {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: true,
    render: (text, record) => (
      <span>
        { (text / 100).toFixed(2).trim() } { getCurrency(record.ccy) }
      </span>
    ),
  }, {
    title: 'Bank',
    dataIndex: 'bank',
  }, {
    title: 'Payment Method',
    dataIndex: 'payment_method',
    render: (data, row) => {
      let result = '';

      switch (data) {
        case 'wallet':
          result = `Regular payment`;
          break;
        case 'monobank':
          result = `Monobank`;
          break;
        case 'apple':
          result = `Apple Pay`;
          break;
        default:
          result = `Bank card`;
      }

      if (row.payment_system) {
        const system = row.payment_system.charAt(0).toUpperCase() + row.payment_system.slice(1);
        result += `, ${system}`;
      }

      return result;
    }
  }, {
    title: 'Project/Jar',
    render: (data, row) => {
      if (row.project) {
        return (
          <a href={row.project.url} target="_blank">{ row.project.name }</a>
        );
      }

      if (row.jar_name) {
        return (
          <>{ row.jar_name }</>
        );
      }
    }
  }, {
    title: 'Description',
    dataIndex: 'destination',
    render: (data, row) => {
      let comment;
      let details = [];
      if (row.Invoice && row.Invoice.full_name) {
        details.push(row.Invoice.full_name);
      }

      if (row.Invoice && row.Invoice.email) {
        details.push(row.Invoice.email);
      }

      if (details.length) {
        const profile = details.join(', ');
          comment = data
            ? `${data} (${profile})`
            : `From ${profile}`;
      }

      return comment ?? data;
    }
  }];

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Search placeholder="Search for payments..." onSearch={onSearch} enterButton allowClear />
      <Table columns={columns} dataSource={data} rowKey="id" pagination={pagination} onChange={handleTableChange} />
    </Space>
  )
};

export default Component;