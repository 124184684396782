import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './components/Home/Home';
import Transfers from './components/Transfers/Transfers';
import Purchases from './components/Purchases/Purchases';
import Categories from './components/Categories/Categories';
import Departments from './components/Departments/Departments';
import MilitaryUnits from './components/MilitaryUnits/MilitaryUnits';
import Projects from './components/Projects/Projects';
import Tags from './components/Tags/Tags';
import Invoices from './components/Invoices/Invoices';
import Payments from './components/Payments/Payments';
import PurchaseForm from './components/Purchases/PurchaseForm/PurchaseForm';
import TransferForm from './components/Transfers/TransferForm/TransferForm';
import CategoryForm from './components/Categories/CategoryForm/CategoryForm';
import ProjectForm from './components/Projects/ProjectForm/ProjectForm';
import DepartmentForm from './components/Departments/DepartmentForm/DepartmentForm';
import MilitaryUnitForm from './components/MilitaryUnits/MilitaryUnitForm/MilitaryUnitForm';
import TagForm from './components/Tags/TagForm/TagForm';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [{
      index: true,
      element: <Home />,
    }, {
      path: "/transfers",
      element: <Transfers />,
    }, {
      path: '/transfers/new',
      element: <TransferForm />
    }, {
      path: '/transfers/:id/edit',
      element: <TransferForm />
    }, {
      path: "/purchases",
      element: <Purchases />,
    }, {
      path: '/purchases/new',
      element: <PurchaseForm />
    }, {
      path: '/purchases/:id/edit',
      element: <PurchaseForm />
    }, {
      path: '/categories',
      element: <Categories />
    }, {
      path: '/categories/new',
      element: <CategoryForm />
    }, {
      path: '/categories/:id/edit',
      element: <CategoryForm />
    }, {
      path: '/projects',
      element: <Projects />
    }, {
      path: '/projects/new',
      element: <ProjectForm />
    }, {
      path: '/projects/:id/edit',
      element: <ProjectForm />
    }, {
      path: '/departments',
      element: <Departments />
    }, {
      path: '/departments/new',
      element: <DepartmentForm />
    }, {
      path: '/departments/:id/edit',
      element: <DepartmentForm />
    }, {
      path: '/military-units',
      element: <MilitaryUnits />
    }, {
      path: '/military-units/new',
      element: <MilitaryUnitForm />
    }, {
      path: '/military-units/:id/edit',
      element: <MilitaryUnitForm />
    }, {
      path: '/invoices',
      element: <Invoices />
    }, {
      path: '/payments',
      element: <Payments />
    }, {
      path: '/tags',
      element: <Tags />
    }, {
      path: '/tags/new',
      element: <TagForm />
    }, {
      path: '/tags/:id/edit',
      element: <TagForm />
    },],
  },
]);

root.render(
  <React.Fragment>
    <RouterProvider router={router} />
  </React.Fragment>
);
